#content {
  background-color: #efeff6;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
}
.btn-info {
  background-color: transparent;
  border: none;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
  display: flex;
  align-items: stretch;
}
#sidebar {
  min-width: 250px;
  max-width: 250px;
  background-color: #53b5ab;
  color: #fff;
  transition: all 0.3s;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}
#sidebar .active-submenu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 80px;
  z-index: 10;
  margin-top: -85px;
  transition: all 0.3s ease-in-out;
  background: #53b5ab;
}
#sidebar .soporte-submenu {
  margin-top: -180px;
}
#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  overflow-y: hidden;
}
#sidebar.active .menu-title {
  display: none;
}
#sidebar.active .main-menu {
  display: none;
}
#sidebar.active .components a:focus,
#sidebar.active .components a:hover ~ .active-submenu,
#sidebar.active .components .active-submenu:hover {
  visibility: initial;
  opacity: 100;
}
#sidebar.active .dropdown-toggle::after {
  content: none;
  border: none;
}
#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
  display: none;
}
#sidebar.active .sidebar-header strong {
  display: block;
}
#sidebar ul li a {
  text-align: left;
}
#sidebar.active ul li a {
  text-align: center;
}
#sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
}
#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}
#sidebar.active ul ul a {
  padding: 10px !important;
}
#sidebar .sidebar-header {
  padding: 20px;
}
#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}
#sidebar ul.components {
  margin-bottom: 0;
}
#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: white;
}
#sidebar ul li a:hover {
  background: #66d6ca;
  text-decoration: none;
}
#sidebar ul li a i {
  margin-right: 10px;
}
#sidebar ul li.active > a,
#sidebar a[aria-expanded="true"] {
  color: #fff;
}
a[data-toggle="collapse"] {
  position: relative;
}
ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
}
ul.CTAs {
  padding: 20px;
}
ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  #sidebar {
    min-width: 150px;
    max-width: 150px;
    text-align: center;
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #sidebar ul li a {
    text-align: center;
  }
  #sidebar.active {
    margin-left: 0 !important;
  }
  #sidebar .sidebar-header h3,
  #sidebar .CTAs {
    display: none;
  }
  #sidebar .sidebar-header strong {
    display: block;
  }
  #sidebar ul li a {
    padding: 20px 10px;
  }
  #sidebar ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul a {
    padding: 10px !important;
  }
  #sidebar ul li a i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
#img-left-menu {
  margin-bottom: 25px;
}
.logo-menu-unactive {
  max-width: 190px !important;
}
.reporte-submenu {
  margin-top: -190px !important;
}
#sidebarCollapse {
  position: absolute;
  background: #53b5ab;
  border-radius: 0;
  z-index: 99;
  border-bottom-right-radius: 8px;
}
#sidebarCollapse:focus {
  border: none;
  box-shadow: none;
}
