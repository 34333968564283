thead tr th {
  background-color: #53b5ab;
  color: white;
}
tbody tr {
  cursor: pointer;
}
tbody tr:hover {
  background-color: #53b5ab78 !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #53b5ab2e;
}
.img-usuario-tabla {
  max-height: 50px;
  max-width: 150px;
}
table {
  min-width: 768px;
  overflow-x: auto;
}
.page-item.active .page-link {
  background-color: #53b5ab;
  border: #53b5ab;
}
.page-link {
  color: #53b5ab;
}
.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 255, 255, 0.25);
}
