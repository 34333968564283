.img-perfil {
  max-width: 100px;
  max-height: 100px;
}
#collapse-parent h3 a {
  color: #53b5ab;
}
.perfil-link:hover {
  color: #6bd8cd !important;
}
h3.card-title {
  margin-bottom: 0;
}
