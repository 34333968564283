body,
html {
  margin: 0;
  height: 100%;
}
html {
  overflow: hidden;
}
#root {
  height: 100%;
}
body {
  overflow: hidden;
}
.refresh-empresas {
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.refresh-empresas:hover {
  font-size: 1.8rem;
}
.refresh-empresas:hover i {
  color: #53b5ab;
}
.custom-file-label::after {
  content: "Seleccionar" !important;
}
#main-div-root > .row {
  height: 100%;
}
#main-div-root > .row .div-max {
  height: 100%;
}
#main-div-root > .row .div-max > .card {
  min-height: 98%;
}
.card {
  min-height: 100%;
}
.card-header {
  background: white;
}
.img-logo {
  max-width: 75px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 5px;
}
.fa-exclamation-triangle {
  color: #e6e62b;
}
.btn-primary:focus,
.btn:focus {
  color: white;
}
.divCounter {
  color: #474747 !important;
}
.divCounter h2 {
  font-weight: normal;
}
h1.titlePage {
  font-size: 1.8rem;
}
#content {
  display: flex;
  flex-direction: column;
}
#content .flex-1 {
  flex: 1;
}
.footer-div {
  background: white;
  padding: 1rem;
}
.footer-div p {
  font-size: 0.8rem;
}
.loader-inside {
  position: relative;
}
.loader-inside .loader-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
