.logo-login {
  width: 100%;
  max-width: 150px;
}
.backgroundimg {
  background-size: cover;
  height: 100%;
  width: 100%;
}
.posabs {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
}
