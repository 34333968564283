.header-menu {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.header-menu .img-logo {
  height: 75px;
  max-width: 100%;
}
.header-menu .img-logo-empresa {
  max-height: 65px;
  max-width: 175px;
}
.header-menu .img-usuario {
  width: 55px;
  height: 55px;
}
.header-menu .nombreUsuario,
.header-menu .empresa-div {
  cursor: pointer;
}
.header-menu .titlePage {
  margin: 0;
  color: #474747;
}
.btn-primary {
  background-color: #53b5ab;
  border-color: #53b5ab;
  font-weight: bold;
  letter-spacing: 1px;
}
.btn-primary:hover {
  background-color: #4dc3b7;
  border-color: #4dc3b7;
}
