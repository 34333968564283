/* Medium Layout: 1280px. */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Tablet Layout: 768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mainmenu-area #primary-menu > ul > li > a {
    padding: 30px 12px;
  }
  .header-area {
    padding-top: 80px;
  }
  .price-table,
  .box {
    padding: 30px 15px;
  }
  .price-table.active {
    padding: 50px 15px;
  }
  .single-team {
    margin-bottom: 30px;
  }
  .side-icon-boxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

/* Mobile Layout: 320px. */

@media only screen and (max-width: 767px) {
  body {
    font-size: 13px;
  }
  .header-area {
    padding-top: 120px;
    padding-bottom: 100px;
    height: auto;
  }
  .page-title h2,
  .header-text h2 {
    font-size: 20px;
  }
  .mainmenu-area .navbar-toggle {
    margin-top: 20px;
  }
  .mainmenu-area .navbar-toggle .icon-bar {
    background-color: #ffffff;
  }
  .mainmenu-area #primary-menu ul {
    margin: 0;
  }
  .mainmenu-area #primary-menu > ul > li > a {
    padding: 15px;
    overflow: hidden;
  }
  .box {
    padding: 30px 15px;
  }
  .box .box-icon {
    height: auto;
  }
  .single-blog,
  .single-team,
  .price-table.active {
    margin-top: 30px;
  }
  .form-double input {
    width: 100%;
  }
  .footer-top {
    margin-bottom: 50px;
  }
  .x-left {
    text-align: left;
  }
  .mainmenu-area .logo {
    margin-left: 15px;
  }
}

/* Wide Mobile Layout: 480px. */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
