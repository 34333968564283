/*-----------------------------------------------------------------------------------
Template Name: New HTML5 Template,
Template URI: http://www.themectg.com
Description: This is html5 template
Author: Themectg
Author URI: http://www.themectg.com
Version: 1.0
-----------------------------------------------------------------------------------
CSS INDEX
===================
1. Google font
2. Theme Default CSS
3. Helper-class
4. Button-style
5. Mainmenu-area
6. Section-Background
7. Header-style
8. Headline-style
9. Skills-area
10. Service-area
11. Work-area
12. Team-area
13. Bar-effect-css
14. Masp style
15. Form-style
16. Scroll-To-Top-Button
17. Preloader-css
-----------------------------------------------------------------------------------*/

/*-----------------
1. Google font
------------------*/

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

/*-------------------
2. Theme Default CSS
--------------------*/

a {
  text-decoration: none;
  outline: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #333333;
}

input:focus,
button:focus,
a:focus,
a:hover {
  text-decoration: none;
  outline: none;
  color: #000000;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px;
  font-weight: 600;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  overflow-y: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8;
  color: #474747;
}

/* Remove Chrome Input Field's Unwanted Yellow Background Color */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

/**
*Helper-Classes
**/

.full-height {
  width: 100%;
  height: 100vh;
}

.relative {
  position: relative;
}

.fixed,
.absolute {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.fixed {
  position: fixed;
}

.v-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.section-padding {
  padding: 120px 0;
}

.social-menu {
  list-style: none;
  margin: 0;
  padding: 10px;
}

.social-menu li {
  display: inline-block;
  margin: 0 3px;
}

.social-menu li a {
  display: block;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 2.5em;
  background-color: #ffffff;
  color: #53b5ab;
  border-radius: 100px;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  font-size: 15px;
}

.social-menu li a:hover {
  background-color: #53b5ab;
  color: #ffffff;
  -webkit-box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
}

/**
*Default Background Class
**/

.overlay:before,
.sky-bg {
  background: #75cbe7;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#44978f),
    to(#2b383b)
  );
  background: linear-gradient(to bottom, #44978f 0%, #2b383b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#44978f ', endColorstr='#2b383b', GradientType=0);
  color: #ffffff;
}

.gray-bg {
  background-color: #f2fefe;
}

/**
*Default Button
**/

.button {
  display: inline-block;
  padding: 8px 30px;
  border-radius: 50px;
  color: #ffffff;
  background-color: #2b383b;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: none;
  -webkit-box-shadow: 2px 5px 20px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 5px 20px -5px rgba(0, 0, 0, 0.1);
}

.button.white {
  background-color: #2b383b;
  color: #ffffff;
}
.button:hover {
  background-color: #ffffff;
  color: #53b5ab;
}

.button.white:hover {
  background-color: #53b5ab;
  color: #ffffff;
}

.button i {
  margin-left: 5px;
}

/**
*Page title
**/

.page-title {
  margin-bottom: 60px;
}

.page-title h2 {
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.page-title h2:after {
  content: "\e675";
  font-family: "themify";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -10px;
  width: 15px;
  height: 15px;
  font-size: 14px;
}

/**
*Box-style
**/

.box {
  padding: 50px 30px;
  text-align: center;
  -webkit-box-shadow: 0 0 0 0 #ffffff;
  box-shadow: 0 0 0 0 #ffffff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 5px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.box:hover {
  -webkit-box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.box h4 {
  text-transform: uppercase;
}

.box .box-icon {
  height: 160px;
  margin-bottom: 20px;
}

.box .box-icon img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.box:hover .box-icon img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

/**
*Mainmenu-area
**/

.mainmenu-area {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding-top: 5px;
}

.overlay,
.overlay a,
.mainmenu-area a {
  color: #ffffff;
}

.mainmenu-area .logo {
  text-transform: uppercase;
  letter-spacing: 2px;
  height: inherit;
  margin-top: 8px;
  padding-left: 0;
}

.mainmenu-area .logo h2 {
  margin-bottom: 0;
}

.mainmenu-area #primary-menu ul > li > a {
  background: none;
  padding: 30px 15px;
  position: relative;
  border-top: 3px solid transparent;
  text-align: center;
}

.mainmenu-area #primary-menu ul > li > a:before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -10px;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid #ffffff;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.mainmenu-area #primary-menu ul > li > a:hover:before,
.mainmenu-area #primary-menu ul > li.active > a:before {
  opacity: 1;
  top: 0;
}

.mainmenu-area #primary-menu ul li.active a,
.mainmenu-area #primary-menu ul li a:hover {
  border-top: 3px solid #ffffff;
}

.mainmenu-area.affix {
  background-color: #44978f;
}

/**
*Header-area
**/

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.header-text h2 {
  margin-bottom: 20px;
  font-size: 40px;
}

.header-text .button {
  margin-top: 30px;
}

.screen-slider .item {
  padding: 56px 22px;
  display: inline-block;
  margin: auto;
}

.screen-slider.owl-carousel .owl-item {
  text-align: center;
}

.screen-slider .owl-controls {
  position: absolute;
  left: 0;
  top: 100%;
}

.screen-slider .owl-controls .owl-nav > div {
  display: inline-block;
  margin: 0 15px;
  font-size: 20px;
  text-align: center;
  position: relative;
}

.screen-slider .owl-controls .owl-nav {
  position: relative;
  margin-top: 0;
}

.screen-slider .owl-controls .owl-nav:after {
  content: "/";
  position: absolute;
  left: 50%;
  top: 12px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/**
*Caption-slider
**/

.caption-slider .carousel-indicators {
  position: static;
  margin: 30px auto 0 auto;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.caption-slider .carousel-indicators li {
  float: left;
  padding-top: 20px;
  padding-right: 30px;
  cursor: pointer;
  position: relative;
  list-style: none;
}

.caption-slider .carousel-indicators li:before {
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  opacity: 0;
}

.caption-slider .carousel-indicators li.active:before {
  opacity: 1;
}

.caption-slider .carousel-indicators li strong {
  display: block;
}

.caption-slider h2 {
  margin-bottom: 20px;
  font-size: 40px;
}

.caption-slider .button {
  margin-top: 30px;
}

.caption-slider .caption-photo {
  display: inline-block;
  -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

.caption-slider .caption-photo.two {
  margin-top: 60px;
}

.caption-slider .caption-title {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.caption-slider .caption-desc {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.caption-slider .caption-button {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.caption-slider .caption-photo.one {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.caption-slider .caption-photo.two {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

/***
*Team-Section
***/

.single-team {
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  color: #515656;
  text-align: center;
  position: relative;
  -webkit-box-shadow: 0 5px 30px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 30px -10px rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single-team:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.single-team .team-photo {
  margin-bottom: 20px;
  padding-top: 20px;
  overflow: hidden;
}

.single-team .team-photo img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single-team:hover .team-photo img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.single-team h4 {
  text-transform: uppercase;
}

.single-team h6 {
  margin-bottom: 20px;
}

.single-team .social-menu {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  bottom: -80px;
  left: 0;
  width: 100%;
  padding: 23px 10px;
  background-color: #ffffff;
}

.single-team:hover .social-menu {
  bottom: 0;
}

/***
*Testimonial-Page
***/

.testimonial-area:before {
  opacity: 0.9;
}

.testimonial {
  text-align: center;
}

.testimonial .testimonial-photo {
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 30px;
  border-radius: 50%;
  -webkit-box-shadow: 5px 10px 30px -10px rgba(255, 255, 255, 0.5);
  box-shadow: 5px 10px 30px -10px rgba(255, 255, 255, 0.5);
  position: relative;
}

.testimonial .testimonial-photo:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
}

.testimonials.owl-carousel .owl-controls .owl-nav {
  text-align: center;
}

/***
*Price-area
***/

.price-table h3 {
  margin-bottom: 30px;
}

.price-table {
  padding: 50px 40px;
  border-radius: 2px;
  text-align: center;
  background-color: #ffffff;
  color: #515656;
  position: relative;
  margin-top: 20px;
}

.price-table.active {
  padding: 70px 40px;
  margin-top: 0;
}

.price-table ul {
  margin: 30px 0;
  padding: 0;
  list-style: none;
}

.price-table ul li {
  padding: 5px 0;
}

.price-table .price-info {
  position: absolute;
  left: 50%;
  top: 0;
  width: 100px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #55a2be;
  color: #ffffff;
  border-radius: 0 0 50% 50%;
  padding-bottom: 5px;
}

/**
*Accordion
**/

#accordion .panel {
  background: none;
}

#accordion .panel .panel-title {
  padding: 30px 0 30px 60px;
  position: relative;
}

#accordion .panel .panel-collapse {
  padding-left: 60px;
}

#accordion .panel .panel-title a span:after,
#accordion .panel .panel-title a span:before,
#accordion .panel .panel-title a span {
  content: "";
  position: absolute;
  left: 0;
  top: 20px;
  width: 36px;
  height: 36px;
  background: #2f60c3;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#accordion .panel .panel-title a span:before,
#accordion .panel .panel-title a span:after {
  width: 10px;
  height: 1px;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

#accordion .panel .panel-title a span:before {
  -webkit-transform: translate(-50%) rotate(90deg);
  transform: translate(-50%) rotate(90deg);
}

#accordion .panel .panel-title a[aria-expanded="true"] span:before {
  -webkit-transform: translate(-50%) rotate(0deg);
  transform: translate(-50%) rotate(0deg);
}

/****
*Blog-area
*****/

.single-blog {
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 10px 15px 50px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 15px 50px -10px rgba(0, 0, 0, 0.1);
}

.single-blog h3 {
  font-size: 18px;
}

.single-blog .blog-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;
}

.single-blog .blog-meta li {
  display: inline-block;
  margin-right: 15px;
}

.single-blog .blog-meta li span {
  margin-right: 5px;
}

.single-blog .blog-content {
  padding: 40px 20px;
  background-color: #ffffff;
}

/***
*Footer-area
***/

.footer-area {
  padding-top: 120px;
  z-index: 1;
}

.footer-bg {
  z-index: -1;
  -webkit-filter: saturate(30%); /* Safari 6.0 - 9.0 */
  filter: saturate(30%);
}

.footer-top {
  margin-bottom: 150px;
}

.side-icon-box {
  margin-bottom: 30px;
  position: relative;
  padding-left: 80px;
}

.side-icon-box a {
  color: #ffffff;
}

.side-icon-box .side-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
}

.side-icon-box strong {
  display: block;
  margin-bottom: 10px;
}

.footer-middle {
  margin-bottom: 60px;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 25px;
  padding-bottom: 15px;
}

/***
*Contact-form
****/

.contact-form .button {
  letter-spacing: 2px;
  min-width: 150px;
  margin-top: 10px;
  border-radius: 3px;
}

.form-control {
  margin-bottom: 20px;
  height: auto;
  padding: 12px 20px;
}

.form-double input {
  width: calc(50% - 10px);
  float: left;
}

.form-double input:last-child {
  float: right;
}

/*-----------
preloader
------------*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#fff)
  );
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#fff ', GradientType=0);
  z-index: 99999999999;
}
.sk-logo {
  margin: 20px auto;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 20%;
  left: 42%;
  margin-top: -20px;
  border-radius: 8px;
}
.sk-folding-cube-landing {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube-landing .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube-landing .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #53b5ab;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube-landing .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube-landing .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube-landing .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube-landing .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube-landing .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube-landing .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.banner-white {
  border-radius: 5px;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.anlge-bg {
  filter: saturate(30%);
}

@media (max-width: 767px) {
  .mainmenu-area {
    text-align: center;
    background-color: #44978f;
  }

  .mainmenu-area .links {
    padding: 20px 0;
  }
}
